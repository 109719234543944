import React, { useState, useEffect } from 'react';
import './booking.css';
import moment from 'moment';
import { BACKEND_URL } from '../../variables.js';
import parseISO from 'date-fns/parseISO';
import { Form, Label, FormGroup, Input, Col, Row, Button, Alert, FormFeedback, FormText } from 'reactstrap';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const axios = require('axios');

const Booking = () => {
	const [arrangementer, setArrangementer] = useState([]);
	const [booking, setBooking] = useState({});
	const [tables, setTables] = useState();
	const [tableTimes, setTableTimes] = useState([]);
	const [availableTables, setAvailableTables] = useState([]);
	const [options, setOptions] = useState([]);
	const [message, setMessage] = useState();
	const [errors, setErrors] = useState({
		name: false,
		mobile: false,
		date: false,
		table: false,
		count: false,
	});
	const [alerts, setAlerts] = useState({
		formSubmitted: false,
		error: false,
		notValid: false,
	});

	useEffect(() => {
		const getArr = async () => {
			await axios
				.get(`${BACKEND_URL}arrangementer/arrangementTodayAndAfter`)
				.then((res) => {
					if (res.data.length > 0) {
						setArrangementer(res.data);
					} else {
						setArrangementer([]);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		};
		getArr();
	}, []);

	const formatDate = (date) => {
		let yyyy = date.getFullYear();
		let mm = date.getMonth() + 1;
		if (mm < 10) mm = '0' + mm;
		let dd = date.getDate();
		if (dd < 10) dd = '0' + dd;

		return yyyy + '-' + mm + '-' + dd;
	};

	const ddmmyyyy = (date) => {
		let yyyy = date.getFullYear();
		let mm = date.getMonth() + 1;
		let dd = date.getDate();
		return dd + '-' + mm + '-' + yyyy;
	};

	const getValidDates = () => {
		let dates = [];
		arrangementer.forEach((arr) => {
			dates.push(parseISO(arr.date));
		});
		return dates;
	};

	const getMaxCapacityBasedOnDate = async (date) => {
		return await axios.get(BACKEND_URL + 'table/maxCapacityBasedOnDate/' + date).then((res) => {
			setTables(res.data.tables);
			generateSelectOptions(res.data.capacity);
			return date;
		});
	};

	const startTimer = () => {
		setInterval(() => {
			setAlerts({
				formSubmitted: false,
				error: false,
				notValid: false,
			});
		}, 10000);
	};

	const submitForm = async (e) => {
		e.preventDefault();
		if (validateForm()) {
			await axios
				.post(BACKEND_URL + 'booking/newBooking', booking)
				.then((res) => {
					if (res.status === 200) {
						setAlerts({ ...alerts, formSubmitted: true });
						alert(
							`Bookingen din ${ddmmyyyy(booking.date)} på bord ${
								tables.filter((t) => parseInt(t.id) === parseInt(booking.table))[0].name
							} for ${booking.count} person(er) er registrert!`
						);
					}
				})
				.catch((err) => {
					console.log(err);
					if (err.response.status === 409) {
						setMessage(err.response.data.message);
					}
					setAlerts({ ...alerts, error: true });
				});
		} else {
			setAlerts({ ...alerts, notValid: true });
		}
		startTimer();
	};

	const onDateChange = (e) => {
		if (validateDate(e)) {
			setErrors({ ...errors, date: false });
			getMaxCapacityBasedOnDate(formatDate(e)).then((date) => {
				setBooking({
					...booking,
					table: 0,
					time: 0,
					count: 0,
					date: parseISO(new Date(date).toISOString()),
				});
			});
		} else {
			setErrors({ ...errors, date: true });
		}
	};

	const onNameMobileOrEmailChange = (e) => {
		if (e.target.name === 'name') {
			setErrors({ ...errors, name: !validateName(e.target.value) });
		} else if (e.target.name === 'mobile') {
			setErrors({ ...errors, mobile: !validateMobile(e.target.value) });
		}
		setBooking({ ...booking, [e.target.name]: e.target.value });
	};

	const validateName = (name) => {
		return /^[a-zA-Z æøåÆØÅ]+$/.test(name);
	};

	const validateMobile = (mobile) => {
		return /^[0-9]{8}$/.test(mobile);
	};

	const validateDate = (date) => {
		return moment(date).isSameOrAfter(moment().format('YYYY-MM-DD'));
	};

	const generateSelectOptions = (cap) => {
		let options = [];
		for (let i = 1; i <= cap; ++i) {
			options.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
		if (options.length === 0) {
			options = [
				<option key={-1} disabled>
					Ingen ledige plasser på valgt dato
				</option>,
			];
		}
		return setOptions(options);
	};

	const generateTableTimes = (table, count = booking.count) => {
		let options = [];
		let filterTables = tables.filter(
			(t) => parseInt(t.id) === parseInt(table) && parseInt(t.capacity) >= parseInt(count)
		);

		let i = 0;

		let timeValues = {
			'18:00 - 21:00': 1,
			'21:00 - 00:00': 2,
			'18:00 - 00:00': 3,
		};

		filterTables.forEach((table) => {
			options.push(
				<option key={i} value={timeValues[table.from + ' - ' + table.to]}>
					{table.from} - {table.to}
				</option>
			);
			i += 1;
		});

		setTableTimes(options);
	};

	const generateAvailableTables = (count) => {
		let filterTables = tables.filter((t) => t.capacity >= parseInt(count));
		let chosenTables = [];
		let options = [];

		let returnTable = 0;

		filterTables.sort((a, b) => {
			if (a.capacity > b.capacity) return -1;
			if (a.capacity < b.capacity) return 1;
			return 0;
		});

		if (filterTables.length > 0) {
			returnTable = filterTables[0].id;
		}

		filterTables.forEach((t) => {
			if (!chosenTables.includes(t.name)) {
				options.push(
					<option key={t.count} value={t.id}>
						{t.name}
					</option>
				);
				chosenTables.push(t.name);
			}
		});
		setAvailableTables(options);
		return returnTable;
	};

	const onTableChange = (e) => {
		generateTableTimes(e.target.value);
		setBooking({ ...booking, [e.target.name]: e.target.value });
	};

	const onCountChange = (e) => {
		const count = e.target.value;
		new Promise((resolve, reject) => {
			let table = generateAvailableTables(count);
			resolve(table);
		}).then((table) => {
			generateTableTimes(table, count);
		});
		setBooking({
			...booking,
			[e.target.name]: e.target.value,
			table: 0,
			time: 0,
		});
	};

	const validateForm = () => {
		return (
			booking.name !== undefined &&
			booking.name !== null &&
			booking.mobile !== undefined &&
			booking.mobile !== null &&
			booking.date !== undefined &&
			booking.date !== null &&
			booking.table !== undefined &&
			booking.table !== 0 &&
			booking.time !== undefined &&
			booking.time !== 0 &&
			booking.count !== undefined &&
			booking.count !== 0 &&
			validateName(booking.name) &&
			validateMobile(booking.mobile) &&
			validateDate(booking.date)
		);
	};

	return (
		<div className='bookingWrapper'>
			<h1>
				Booking hos{' '}
				<span role='img' aria-label='heart'>
					❤️
				</span>
				Realfagskjelleren
				<span role='img' aria-label='heart'>
					❤️
				</span>{' '}
				i Trondheim
			</h1>
			Vi lagrer navnet og mobilnummeret ditt! Dette er for smittesporing, og sjekke booking ved ankomst
			på Kjellern. Dataen brukes ikke til noe annet og slettes etter 14 dager!
			<div className='formContainer'>
				<Form>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='name'>Navn</Label>
								<Input
									type='text'
									id='name'
									name='name'
									invalid={errors.name}
									onChange={(e) => onNameMobileOrEmailChange(e)}></Input>
								<FormFeedback>
									Du har feil i navnet ditt. Gyldige bokstaver er "A-z" + "ÆØÅ/æøå"
								</FormFeedback>
								<FormFeedback valid={true}>Nice! Navnet er skrevet rett</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='mobile'>Telefonnummer</Label>
								<Input
									type='mobile'
									id='mobile'
									name='mobile'
									invalid={errors.mobile}
									onChange={(e) => onNameMobileOrEmailChange(e)}></Input>
								<FormFeedback invalid='true'>
									Du har en feil i nummeret ditt. Nummeret skal skrives uten landskode, med
									8 siffer.
								</FormFeedback>
								<FormFeedback valid={true}>Nice! Mobilnummeret er gyldig.</FormFeedback>
							</FormGroup>
						</Col>
					</Row>
					{/* <Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='email'>Epost (frivillig)</Label>
								<Input
									type='email'
									id='email'
									name='email'
									onChange={(e) =>
										onNameMobileOrEmailChange(e)
									}></Input>
								<FormText>
									Epost er frivillig og brukes kun til
									bookingbekreftelse og avbestilling
								</FormText>
							</FormGroup>
						</Col>
					</Row> */}
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='date'>Dato</Label>
								<br />
								<DatePicker
									autoComplete='off'
									name='date'
									id='date'
									selected={booking.date}
									onChange={(date) => onDateChange(date)}
									includeDates={getValidDates()}
									highlightDates={getValidDates()}
									dateFormat='dd/MM/yyyy'
									placeholderText='Velg dato for booking'
								/>
							</FormGroup>
						</Col>
					</Row>

					{booking.date && validateDate(booking.date) && (
						<Row form>
							<Col md={12}>
								<FormGroup>
									<Label for='count'>Antall</Label>
									<Input
										type='select'
										name='count'
										id='count'
										invalid={errors.count}
										value={booking.count}
										onChange={(e) => onCountChange(e)}>
										<option value={0} disabled>
											Velg antall
										</option>
										{options}
									</Input>
								</FormGroup>
							</Col>
						</Row>
					)}
					{tables && booking.count && (
						<Row form>
							<Col md={12}>
								<FormGroup>
									<Label for='table'>Bord</Label>
									<Input
										type='select'
										name='table'
										id='table'
										invalid={errors.table}
										value={booking.table}
										onChange={(e) => onTableChange(e)}>
										<option value={0} disabled>
											Velg bord
										</option>
										{availableTables}
									</Input>
								</FormGroup>
							</Col>
						</Row>
					)}
					{booking.table && booking.count && (
						<Row form>
							<Col md={12}>
								<FormGroup>
									<Label for='time'>Tid</Label>
									<Input
										type='select'
										name='time'
										id='time'
										invalid={errors.time}
										value={booking.time}
										onChange={(e) =>
											setBooking({
												...booking,
												[e.target.name]: e.target.value,
											})
										}>
										<option value={0} disabled>
											Velg tid
										</option>
										{tableTimes}
									</Input>
								</FormGroup>
							</Col>
						</Row>
					)}
					{validateForm() && <Button onClick={(e) => submitForm(e)}>Send booking!</Button>}
				</Form>
			</div>
			<div>
				{alerts.formSubmitted && (
					<Alert color='success'>
						Bookingen din {ddmmyyyy(booking.date)} på bord{' '}
						{tables.filter((t) => parseInt(t.id) === parseInt(booking.table))[0].name} for{' '}
						{booking.count} person(er) er registrert!
					</Alert>
				)}
				{alerts.notValid && <Alert color='danger'>Det er noe feil med bookingen</Alert>}
				{alerts.error && (
					<Alert color='warning'>{message !== undefined ? message : 'Noe gikk galt :c'}</Alert>
				)}
			</div>
			<p>
				Dersom du ikke møter innen 30 minutter fra bookingens start uten å ha gitt beskjed til
				Kjellern på Facebook eller til en av de som jobber denne kvelden, vil du miste bookingen din!
				<br />
				<i>
					Bookingsystemet er under utvikling. Mye mulig det finnes bugs. Måter booking skjer på kan
					også endres i fremtiden.
					<br />
					<br />
					Send Kjellern en melding på{' '}
					<a
						href='https://www.facebook.com/Realfagskjelleren/'
						target='_blank'
						rel='noopener noreferrer'>
						Facebook
					</a>{' '}
					eller <a href='mailto:styret@realfagskjelleren.no'>på epost</a> dersom du har problemer
					med bookingen eller opplever bugs.
				</i>
			</p>
		</div>
	);
};

export default Booking;

/* 
<Row form>
					<Col md={12}>
						<FormGroup>
							<Label for='date'>Dato</Label>
							<Input
								type='date'
								max={getLastDate()}
								min={getToday()}
								name='date'
								id='date'
								invalid={errors.date}
								onChange={(e) => onDateChange(e)}
							/>
							<FormFeedback invalid='true'>
								Dato må være i dag eller senere.
							</FormFeedback>
						</FormGroup>
					</Col>
				</Row>
*/
