import React from 'react';
import './App.css';

// Component imports
import Booking from './components/booking/booking.jsx';

function App() {
	return (
		<div className='App'>
			<Booking />
		</div>
	);
}

export default App;
